// FlixCard.js
import { connect } from 'react-redux';
import withRouter from '../component/WithRouter.jsx';

import {
  FLIX_DISPLAY_UNLOCK_COUNT,
  FEATURE_DISPLAY_NAME,
} from '../RemoteConfigKeys.js';

import FlixCard from '../component/FlixCard.jsx';

import getMessageData from '../selector/getMessageData.js';
import getMeData from '../selector/getMeData.js';
import getUserData from '../selector/getUserData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getMessageDataWithVariable, {
  VariableName,
} from '../selector/getMessageDataWithVariable.js';
import fetchMessageDetail from '../action/fetchMessageDetail.js';
import fetchDrmLicenseAvailability from '../action/fetchDrmLicenseAvailability.js';

import { getIsOnMobile } from '../resource/getUserAgent.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';

const mapStateToProps = (state, { messageId, shouldShowTooltip, location }) => {
  if (!messageId) {
    // We only need placeholder here, so let's skip the other calculation.
    return {};
  }
  const userId = messageId && getMessageData(state, messageId, 'senderId');
  const meUsername = getMeData(state, 'username');
  const isMobile = getIsOnMobile();
  const isAuthed = !!getMeData(state, 'id');
  const hasAmateur = getMessageData(state, messageId, 'hasAmateur');
  return {
    id: messageId,
    userId,
    username: getUserData(state, userId, 'username'),
    displayName: getUserData(state, userId, 'displayName'),
    originalTitle: getMessageDataWithVariable(
      state,
      messageId,
      'originalTitle',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    originalCaptionText: getMessageDataWithVariable(
      state,
      messageId,
      'originalCaptionText',
      `${VariableName.USERNAME}:${meUsername}`
    ),
    enableTooltip: shouldShowTooltip && isAuthed && !isMobile,
    isAuthed,
    isPro: getMessageData(state, messageId, 'isPro'),
    hasFreePeek: getMessageData(state, messageId, 'hasFreePeek'),
    isOnFreezone: getIsMatchedPathname({
      pathname: location?.pathname,
      dataKey: 'isOnFreezone',
    }),
    hasAmateur,
    shouldShowUnlockCount: getRemoteConfigData(
      state,
      FLIX_DISPLAY_UNLOCK_COUNT
    ),
    badges: getMessageData(state, messageId, 'badges'),
    unlockPrice: getMessageData(state, messageId, 'unlockPrice'),
    canViewMedia: getMessageData(state, messageId, 'canViewMedia'),
    isDisplayNameEnabled:
      getRemoteConfigData(state, FEATURE_DISPLAY_NAME) === 1,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMessageDetail: ({ messageId }) =>
      dispatch(fetchMessageDetail({ id: messageId })),
    fetchDrmLicenseAvailability: ({ messageId, keySystem }) =>
      dispatch(fetchDrmLicenseAvailability({ messageId, keySystem })),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    // equals to `{ ...ownProps, ...stateProps, ...dispatchProps }`
    // ref: https://github.com/reduxjs/react-redux/blob/v7.1.0/src/connect/mergeProps.js
    null,
    { forwardRef: true } // Required by WithIntersectionObserver
  )(FlixCard)
);
